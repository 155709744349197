<template>
    <div class="main-container">
      <h5 class="pageTitle">관리자 등록</h5>
  
      <!-- 등록 폼 -->
      <div class="form-container">
        <!-- 교관 정보 선택 -->
        <div class="form-group">
        <label for="instructorSelect">교관 정보( 관리자가 교관일 경우 선택해 주세요! )</label>
        <select 
            v-model="formData.instructor_UUID"
            class="input-box"
            @change="onInstructorChange"
            
        >
            <option value="">교관을 선택하세요</option>
            <option
            v-for="instructor in instructors"
            :key="instructor.uuid"
            :value="instructor.uuid"
            >
            {{ instructor.name }} ({{ instructor.no }})
            </option>
        </select>
        <p v-if="errors.instructor_UUID" class="error">{{ errors.instructor_UUID }}</p>
        </div>
        <!-- 관리자 이름 -->
        <div class="form-group">
          <label for="name">관리자 이름</label>
          <input v-model="formData.name" type="text" class="input-box" :readonly="!!formData.instructor_UUID" placeholder="관리자 이름을 입력하세요"  />          
        </div>
        
        <!-- 관리자 아이디 -->
        <div class="form-group">
          <label for="id">관리자 아이디</label>
          <input v-model="formData.id" type="text" class="input-box" placeholder="관리자 아이디를 입력하세요" @blur="validateId" autocomplete="off" />
          <p v-if="errors.id" class="error">{{ errors.id }}</p>
        </div>
  
        <!-- 비밀번호 -->
        <div class="form-group">
          <label for="password">비밀번호</label>
          <input v-model="formData.password" type="password" class="input-box" placeholder="비밀번호를 입력하세요" autocomplete="new-password" />
        </div>
  
        <!-- 관리자 구분 -->
        <div class="form-group">
          <label for="admin_type">관리자 구분</label>
          <select v-model="formData.admin_type" @change="handleAdminTypeChange" class="input-box">
            <option value="OPERATOR">운영자</option>
            <option value="INSTRUCTOR">교관</option>
            <option value="DIRECTOR">원장</option>
          </select>
        </div>
        <!-- 경고 메시지 -->
        <div v-if="showWarning" class="form-group">
            <p class="error">
            상위에서 교관정보를 선택시 관리자 구분은 반드시 "교관" 또는 "원장"으로 선택하셔야합니다!
            </p>
        </div>
        <!-- 전화번호 -->
        <div class="form-group">
          <label for="mobile_no">전화번호</label>
          <input  class="input-box" 
                  type="text"
                  placeholder="전화번호를 입력하세요" 
                  :value="formData.mobile_no"
                  @input="handleMobileNoInput"
                  @blur="validateMobileNo" 
           />
          <p v-if="errors.mobile_no" class="error">{{ errors.mobile_no }}</p>
        </div>
  
        <!-- SMS 수신 여부 -->
        <div class="form-group">
          <label for="sms_YN">SMS 수신 여부</label>
          <select v-model="formData.sms_YN" class="input-box">
            <option value="true">예</option>
            <option value="false">아니오</option>
          </select>
        </div>
  
        <!-- 버튼 그룹 -->
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-register" @click="validateAndRegister">등록</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";
  import {sendWorkLog} from "@/lib/logModule";
  import DailyCheckModule from "@/lib/DailyCheckModule";
  
  export default {
    data() {
      return {
        instructors: [],
        formData: {
          name: "",
          id: "",
          password: "",
          admin_type: "INSTRUCTOR",
          retired_YN: false,
          mobile_no: "",
          sms_YN: true,
        },
        showWarning: false, 
        errors: {
          id: "",
          mobile_no: "",
          instructorNo: "",
        },
        isFormValid: true,
      };
    },
    computed:{
      senderNumber() {
        return this.$store.state.senderNumber; // Vuex state에서 발신 번호 가져오기
      },
    },
    mounted() {
        this.fetchInstructors(); // 화면 로드 시 교관 데이터 로드
        this.fetchResults();
    },
    methods: {
      prepareMobileNoForDB() {
        return this.formData.mobile_no.replace(/-/g, ""); // 하이픈 제거
      },

     
      validateId() {
        const { id } = this.formData;
        if (this.existingAdmins.some((admin) => admin.id === id)) {
          this.errors.id = "중복된 관리자 아이디입니다.";
        } else {
          this.errors.id = "";
        }
        this.updateFormValidity();
      },
      // formatMobileNo() {
      //     // 입력값에서 숫자만 추출
      //     const rawNumber = this.formData.mobile_no.replace(/[^0-9]/g, "");
          
      //     // 숫자가 11자리를 초과하는 경우
      //     if (rawNumber.length > 11) {
      //       this.errors.mobile_no = "전화번호 자리수 이상!"; // 에러 메시지 설정
      //       return; // 포맷팅 중단
      //     }

      //     // 01로 시작하지 않으면 에러 처리
      //     if (!rawNumber.startsWith("01")) {
      //       this.errors.mobile_no = "전화번호는 01로 시작해야 합니다.";
      //       return; // 포맷팅 중단
      //     }

      //     // 에러 초기화
      //     this.errors.mobile_no = "";

      //     // 하이픈 포함 포맷 변환
      //     if (rawNumber.length <= 3) {
      //       this.formData.mobile_no = rawNumber;
      //     } else if (rawNumber.length <= 6) {
      //       this.formData.mobile_no = `${rawNumber.slice(0, 3)}-${rawNumber.slice(3)}`;
      //     } else if (rawNumber.length <= 10) {
      //       this.formData.mobile_no = `${rawNumber.slice(0, 3)}-${rawNumber.slice(3, 6)}-${rawNumber.slice(6)}`;
      //     } else {
      //       this.formData.mobile_no = `${rawNumber.slice(0, 3)}-${rawNumber.slice(3, 7)}-${rawNumber.slice(7)}`;
      //     }
      //   },
      formatMobileNo(mobileNo) {
          if (!mobileNo) {
            this.errors.mobile_no = ""; // 에러 초기화
            return ""; // 전화번호가 없는 경우 빈 문자열 반환
          }

          // "01"로 시작하지 않으면 에러 처리
          if (!mobileNo.startsWith("01")) {
            this.errors.mobile_no = "전화번호는 01로 시작해야 합니다.";
            return mobileNo; // 기존 입력값 반환 (입력 차단 방지)
          }

          this.errors.mobile_no = ""; // 에러 초기화 (유효한 입력일 경우)

          // 길이에 따라 포맷 적용
          if (mobileNo.length === 10) {
            return mobileNo.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
          } else if (mobileNo.length === 11) {
            return mobileNo.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
          } else {
            return mobileNo; // 예상치 못한 형식 그대로 반환
          }
        },
        handleMobileNoInput(event) {
          const onlyNumbers = event.target.value.replace(/[^0-9]/g, ""); // 숫자만 남김
          if (onlyNumbers.length > 11) {
            this.errors.mobile_no = "전화번호 자리수 이상!";
            this.formData.mobile_no = this.formatMobileNo(onlyNumbers.slice(0, 11)); // 11자리로 제한
            event.target.value = this.formData.mobile_no; // 입력 필드 업데이트
            return;
          }
          this.errors.mobile_no = "";
          this.formData.mobile_no = this.formatMobileNo(onlyNumbers); // 포맷 적용
          event.target.value = this.formData.mobile_no; // 입력 필드 업데이트
        },
        validateMobileNo() {
          const mobileRegex = /^01[0-9]-\d{3,4}-\d{4}$/;

          if (this.errors.mobile_no) {
            return; // 이미 formatMobileNo에서 에러 메시지가 설정된 경우 처리하지 않음
          }

          // 전화번호 형식 검사
          if (!mobileRegex.test(this.formData.mobile_no)) {
            this.errors.mobile_no = "유효한 전화번호 형식이 아닙니다.";
          } else {
            // 입력된 전화번호에서 하이픈 제거
            const formattedMobile = this.formData.mobile_no.replace(/-/g, "");

            // 기존 데이터와 중복 여부 검사 (DB에 저장된 전화번호에서도 하이픈 제거 후 비교)
            const isDuplicate = this.existingAdmins.some(
              (admin) => admin.mobile_no.replace(/-/g, "") === formattedMobile
            );

            if (isDuplicate) {
              this.errors.mobile_no = "중복된 전화번호입니다.";
            } else {
              this.errors.mobile_no = ""; // 유효하면 에러 초기화
            }
          }

          this.updateFormValidity();
        },

        updateFormValidity() {
          this.isFormValid =
            !this.errors.id && !this.errors.mobile_no && !this.errors.instructorNo;
        },

        onInstructorChange() {
          // 선택된 교관 정보 처리
          const selectedInstructor = this.instructors.find(
            (instructor) => instructor.uuid === this.formData.instructor_UUID
          );

          if (selectedInstructor) {
            this.formData.name = selectedInstructor.name; // 교관 이름을 관리자 이름에 설정
            this.formData.admin_type = "INSTRUCTOR"; // 관리자 구분을 교관으로 설정
          } else {
            this.formData.name = ""; // 선택 취소 시 초기화
            this.formData.admin_type = ""; // 초기화
          }

          // 중복 교관 체크
          const isDuplicate = this.existingAdmins.some(
            (admin) => admin.instructor_UUID === this.formData.instructor_UUID
          );

          if (isDuplicate) {
    this.errors.instructor_UUID = "중복된 교관 정보입니다.";
  } else if (
    this.formData.instructor_UUID &&
    this.formData.admin_type !== "INSTRUCTOR"
  ) {
    // 교관이 선택되었는데 관리자 구분이 교관이 아닌 경우
    this.errors.instructor_UUID = "교관 정보가 선택되었지만 관리자 구분이 올바르지 않습니다.";
  } else {
    this.errors.instructor_UUID = ""; // 유효하면 에러 초기화
  }

          this.updateFormValidity();
        },

     
       

        goToList() {
            this.$router.push({ name: "admin-read" });
        },
        async fetchInstructors() {
            try {
            const tokenData = JSON.parse(sessionStorage.getItem("token"));
            const token = tokenData ? tokenData.access_token : "";

            const response = await axios.get("/admins/instructor_read", {
                headers: {
                Authorization: `Bearer ${token}`,
                },
            });

            this.instructors = response.data; // 교관 데이터 저장
            } catch (error) {
            console.error("교관 정보 불러오기 실패:", error);
            Swal.fire("오류", "교관 데이터를 불러오는 데 실패했습니다.", "error");
            }
        },
        async fetchResults() {
          try {
            const tokenData = JSON.parse(sessionStorage.getItem("token"));
            const token = tokenData ? tokenData.access_token : "";

            const response = await axios.get("/admins/read", {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            this.existingAdmins = response.data; // 기존 관리자 데이터 저장
          } catch (error) {
            Swal.fire("오류", "관리자 데이터를 불러오는 데 실패했습니다.", "error");
          }
        },
        handleAdminTypeChange() {
          // 관리자 구분 변경 시 처리
          if (this.formData.admin_type === "OPERATOR" && this.formData.instructor_UUID) {
            this.showWarning = true; // 경고 메시지 표시
          } else {
            this.showWarning = false; // 경고 메시지 숨기기
          }
        },
               
        async validateAndRegister() {
          if (!this.isFormValid) {
              Swal.fire({
                icon: "error",
                title: "등록 실패",
                text: "입력 데이터에 오류가 있습니다. 확인 후 다시 시도해주세요.",
              });
              return;
            }
            if (!this.formData.name || !this.formData.id || !this.formData.password || !this.formData.mobile_no) {
                Swal.fire({
                    icon: "error",
                    title: "등록 실패",
                    text: "모든 필드를 입력해야 합니다.",
                });
                return;
            }
    
            // 교관일 경우 상위 교관 정보 선택 확인
            if (this.formData.admin_type === "INSTRUCTOR" && !this.formData.instructor_UUID) {
                Swal.fire({
                icon: "error",
                title: "등록 실패",
                text: "교관 정보를 선택하거나 등록이 필요합니다.",
                });
                return;
            }

            try {
                const tokenData = JSON.parse(sessionStorage.getItem("token"));
                const token = tokenData ? tokenData.access_token : "";
                //console.log("this.formData.sms_YN: "+this.formData.sms_YN);
                //const sms_YN = this.formData.sms_YN ==="true"
                const requestData = {
                    ...this.formData,
                    retired_YN: false,
                    sms_YN: this.formData.sms_YN === true || this.formData.sms_YN === "true",
                    mobile_no: this.prepareMobileNoForDB(),
                };
        
                await axios.post(
                    "/admins/register",
                    requestData,
                    {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    }
                );
                //console.log("관리자등록 로깅!!");
                sendWorkLog('100300');    

                //console.log("Admin-create=> SMS recreate!!!")
                await DailyCheckModule.recreateSmsData(this.senderNumber);
                  
                Swal.fire({
                    icon: "success",
                    title: "등록 성공",
                    text: "관리자가 등록되었습니다.",
                });
                this.goToList();
            } catch (error) {
              console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "등록 실패",
                    text: "등록 중 문제가 발생했습니다.",
                });
            }
        },
    },
    
   
  };
  </script>
  
  <style scoped>
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .input-box {
    flex: 1;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    max-width: 400px;
  }
  
  .button-container {
    margin-top: 40px;
    display: flex;
    gap: 20px;
  }
  
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-register {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-list:hover {
    background-color: #e68900;
  }
  
  .button-register:hover {
    background-color: #3e8e41;
  }
  .error {
    color: red; /* 오류를 강조하기 위해 빨간색 사용 */
    font-size: 12px; /* 메시지 크기를 작게 설정하여 레이아웃을 방해하지 않음 */
    margin-top: 4px; /* 입력 필드와의 간격을 약간 둠 */
    font-weight: bold; /* 메시지를 강조 */
    line-height: 1.5; /* 메시지가 읽기 좋도록 줄 간격 설정 */
  }
  </style>
  